import React, { Component } from "react"
import "../Style/style.scss"
import * as SVG from "../svgs.js"
import InlineSVG from "svg-inline-react"
import Header from "../components/header"
import BackgroundImage from "../components/BackgroundImage"

class Animations extends Component {
  render() {
    return (
      <BackgroundImage>
        <Header />
        <section className="animations">
          <div className="wrapper">
            <div className="row">
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.loss} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.love} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.life} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.tower} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.afterlife} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.marriage} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.spectre} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.oracle} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.mischief} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.writer} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.triptych} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.children} />
                </div>
              </div>
              <div className="col col-3">
                <div className="tarot_card">
                  <InlineSVG src={SVG.moon} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
    )
  }
}

export default Animations
